import { CheckCircleIcon, ExclamationIcon, InformationCircleIcon } from '@heroicons/react/outline'
import { ComponentProps, ComponentType } from 'react'
import { ThemeColor } from '../../../services/Service.types'

type AlertColorConfig = {
    className: string
    icon: ComponentType<ComponentProps<'svg'>>
}

type AlertConfig = {
    colors: Record<ThemeColor, AlertColorConfig>
}

export const alertConfig: AlertConfig = {
    colors: {
        primary: {
            className: 'bg-primary-100 text-primary',
            icon: InformationCircleIcon
        },
        secondary: {
            className: 'bg-secondary-100 text-secondary',
            icon: InformationCircleIcon
        },
        success: {
            className: 'bg-success-100 text-success',
            icon: CheckCircleIcon
        },
        info: {
            className: 'bg-info-100 text-info',
            icon: InformationCircleIcon
        },
        warning: {
            className: 'bg-warning-100 text-warning',
            icon: ExclamationIcon
        },
        danger: {
            className: 'bg-danger-100 text-danger',
            icon: ExclamationIcon
        },
        gray: {
            className: 'bg-gray-100 text-gray',
            icon: InformationCircleIcon
        },
        orange: {
            className: 'bg-orange-100 text-orange',
            icon: InformationCircleIcon
        },
        purple: {
            className: 'bg-purple-100 text-purple',
            icon: InformationCircleIcon
        }
    }
}
